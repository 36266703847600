import React from "react"
import { Link } from "gatsby"
// import classNames from 'classnames'

const Columnar = ({content}) => {

  return (
    <div className="columnar">
      <div className="container columnar-wrapper">
        {
          content.map((block, i) => {
            return (
              <div key={i} className="content-wrapper">
                <div>
                  <h2 className="title">{block.title}</h2> 
                  <div className="list">
                    {block.list.map((item, i) => {
                      return (
                        <div key={i} className="list-item">
                          <h3 className="list-item-title">{item.title}</h3>
                          <p className="list-item-description">{item.description}</p>
                        </div>
                      )
                    })}
                  </div>
                  { 
                    block.btnText && 
                    <div className="btn-wrapper">
                      {block.isButton ?
                        <button className={`${block.btnClass} btn-play`}> 
                          <img 
                            src={require('../images/play.svg')}
                            className="play-svg"
                            alt="play"
                          />
                          {block.btnText} 
                        </button>
                        :
                        <Link to="/" className={`btn-play ${block.btnClass}`}> 
                        <img 
                          src={require('../images/play.svg')}
                          alt="play"
                          className="play-svg"
                        />
                        {block.btnText} 
                      </Link>
                    } 
                    </div>
                  }
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default Columnar