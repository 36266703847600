import React from "react"
import { Link } from "gatsby"
import classNames from 'classnames'
import ScheduleADemoButton from '../components/schedule-a-demo-button'
import Img from "../components/image-transform"
 
const CustomerJourney = ({content}) => {
  return (
    <div className="section-customer-journey">
      <div className="container">
        <div className="content-wrapper stacked">
          <div className="stacked-text-wrapper">
            {
              content.stackedTexts.map((item, i) => {
                return (
                  <>
                    <div className={classNames(
                      'text-holder', {
                        right: item.align === 'right'
                      }
                    )}>
                      <h2 className="title">{item.title}</h2> 
                      <div className="list">
                        {item.list.map((list, i) => {
                          return (
                            <div key={i} className="list-item">
                              <h3 className="list-item-title">{list.title}</h3>
                              <p className="list-item-description">{list.description}</p>
                            </div>
                          )
                        })}
                      </div>
                      {
                        item.buttons !== undefined  &&
                        <div className="btn-wrapper">
                          {
                            item.buttons.map((btn, i) => {
                              return (
                                  btn.scheduleButton ?
                                  <ScheduleADemoButton key={i} buttonColor={btn.btnColor}/>
                                  :
                                  btn.urlType === 'external' ?
                                    <a href={btn.url}
                                    key={i}
                                    target="_blank" rel="noopener noreferrer"
                                    className={
                                    classNames('btn', btn.classConfig, {
                                      'btn-blue' : btn.btnColor === 'blue',
                                      'btn-orange' : btn.btnColor === 'orange',
                                      'btn-black' : btn.btnColor === 'black',
                                    }
                                    )}>
                                        { btn.btnText }
                                    </a>
                                  :
                                  btn.urlType === 'internal' &&
                                    <Link to={btn.url}
                                    key={i}
                                    className={
                                    classNames('btn', btn.classConfig, {
                                      'btn-blue' : btn.btnColor === 'blue',
                                      'btn-orange' : btn.btnColor === 'orange',
                                      'btn-black' : btn.btnColor === 'black',
                                    }
                                    )}>
                                        { btn.btnText }
                                    </Link>
                              )
                            })
                          }
                        </div>
                      }
                    </div>
                  </>
                )
              })
            }
          </div>
          {
            content.image &&
            <div className="image-holder">
              {content.svg ?
              <img src={content.image} className="image" alt={content.imageName}/>
              :
              content.image &&
              <Img filename={content.image} className="image" alt={content.imageName}/>
            }
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default CustomerJourney